import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import styled from 'styled-components'

import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/themes/prism.css'

const StyledEditor = styled(Editor)`
  border: '1px solid lightgray';
`

const CodeEditor = (props) => {
  const { lang = 'js', value = '', className = '' } = props
  const onValueChange = props.onChange || console.log
  return (
    <StyledEditor
      className={className}
      value={value}
      onValueChange={onValueChange}
      highlight={code => highlight(code, languages[lang])}
      padding={10}
    />
  )
}

export default CodeEditor
