import axios from 'axios'
import format from 'date-fns/format'

const { localStorage } = window
const { API_ENDPOINT } = process.env

const ENDPOINT = API_ENDPOINT

const storeToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token))
}

const getToken = () => {
  let json = localStorage.getItem('token')
  if (json) {
    json = cleanToken(json)
    return JSON.parse(json)
  } else {
    return null
  }
}

const cleanToken = (token) => {
  return token
    .replace('JWT', '')
    .replace(' ', '')
}

const removeToken = () => {
  localStorage.removeItem('token')
  return true
}

export const getUser = () =>
  new Promise((resolve, reject) => {
    const { localStorage } = window
    const json = localStorage.getItem('user')
    if (json) {
      const user = json.parse(json)
      resolve(user)
    } else {
      reject(new Error('No user stored.'))
    }
  })

const token = getToken()

const instance = axios.create({
  baseURL: ENDPOINT,
  withCredentials: false, // set cookies via ajax,
  headers: token
    ? { Authorization: `Bearer ${token}` }
    : {}
})

const updateInstanceAuthToken = (token) => {
  instance.defaults.headers.Authorization = `Bearer ${token}`
}

export const login = (username, password) =>
  new Promise((resolve, reject) => {
    const data = { username, password }
    instance.post('/login', data)
      .then((res) => {
        const { token } = res.data
        const ctoken = cleanToken(token)
        storeToken(ctoken)
        updateInstanceAuthToken(ctoken)
        resolve({
          res,
          user: res.data.auth,
          token: token
        })
      })
      .catch(reject)
  })

export const updatePasswordWithKey = (payload) =>
  new Promise((resolve, reject) => {
    instance.post('/user/reset_password', payload)
      .then((res) => {
        resolve(res)
      })
      .catch(reject)
  })

export const getLogs = () =>
  instance.get('/v3/debugLog')

export const updateOrAddAlgoByKeyForSpace = (algoKey, spaceId, spaceAlgorithm = {}) =>
  instance.post(`/v3/space/${spaceId}/algorithm/key/${algoKey}`, { spaceAlgorithm })

export const getAlgoByKeyForSpace = (algoKey, spaceId) =>
  instance.get(`/v3/space/${spaceId}/algorithm/key/${algoKey}`)

export const getAlgoByKey = (algoKey) =>
  instance.get(`/v3/algorithms/key/${algoKey}`)

export const getMeasurementsForSpace = (spaceId, opts = {}) =>
  new Promise((resolve, reject) => {
    instance.get(`/spaces/${spaceId}/measurements`, {
      params: {
        dateFrom: new Date() - 20,
        dateTo: new Date(),
        interval: 3600,
        ...opts
      }
    }).then((res) => {
      resolve(res.data)
    }).catch(reject)
  })

export const isSignedIn = () =>
  new Promise((resolve, reject) => {
    instance.get('/userping')
      .then((res) => {
        resolve(res.status === 200)
      })
      .catch(reject)
  })

export const logout = () =>
  new Promise((resolve, reject) => {
    const { localStorage } = window
    const user = localStorage.removeItem('user')
    removeToken()
    resolve(user)
  })

export const getSensors = (username) =>
  new Promise((resolve, reject) => {
    instance.get(`/sensors/${username}`)
      .then((res) => resolve({
        res,
        sensors: res.data
      }))
      .catch(reject)
  })

export const getSensorTypes = async () => {
  const response = await instance.get('/sensorTypes')
  return response.data
}

export const updateAlgorithm = (algorithmId, newData) => {
  return instance.patch(`/v3/algorithms/${algorithmId}`, newData)
}
export const updateSpaceAlgorithm = (spaceAlgoId, newData) =>
  instance.patch(`/v3/spaceAlgorithms/${spaceAlgoId}`, newData)

export const addSpaceAlgo = (spaceId, algorithmId, userSettings) =>
  instance.post(`/v3/spaces/${spaceId}/algorithms`, {
    algorithmId,
    userSettings: {
      user: userSettings
    }
  })

export const getSensorStats = (id) =>
  instance.get(`/sensor/diagnostics/${id}`)

export const addAlgorithm = (algo) =>
  instance.post('/v3/algorithms', algo)

export const getAlgorithmsForSpace = (spaceId) =>
  instance.get(`/v3/spaces/${spaceId}/algorithms`)

export const getAllAlgorithms = () =>
  instance.get('/v3/algorithms')

export const getSensorPlugins = () =>
  instance.get('/v3/sensorPlugins')

export const getAlgorithm = (algoId) =>
  instance.get(`/v3/algorithms/${algoId}`)

export const adminGetAllSensors = () =>
  instance.get('/su/sensors')

export const adminGetSensor = (id) =>
  instance.get(`/v3/sensors/${id}`)

export const adminGetAllUsers = () =>
  instance.get('/su/users')

export const adminGetUser = (userId) =>
  instance.get(`/su/user/${userId}`)

export const adminGetUserWithSpaceAccess = (spaceId) =>
  instance.get(`/v3/spaces/${spaceId}​/access`)

export const adminGetAllSpaces = () =>
  instance.get('/su/spaces')

export const adminAddSensorToUser = (payload) =>
  instance.post('/v3/userSensor', payload)

export const adminRemoveSensorFromUser = (userId, sensorId) =>
  instance.delete(`/v3/userSensor/${userId}/${sensorId}`)

export const adminAddSensor = (sensor) =>
  instance.post('/su/sensor', sensor)

export const adminUpdateSensor = (sensor) =>
  instance.put('/su/sensor', sensor)

export const adminDeleteSensor = (sensorId) =>
  instance.delete(`/su/sensor/${sensorId}`)

export const adminAddUser = (user) =>
  instance.post('/su/user', user)

export const adminUpdateUser = (user) =>
  instance.patch('/su/user', user)

export const adminDeleteUser = (userId) =>
  instance.delete(`/su/user/${userId}`)

export const adminAddUserToSpace = (user) =>
  instance.post('/v3/spaceAccess', user)

export const adminRemoveUserFromSpace = (spaceId, userId) =>
  instance.delete(`/v3/spaceAccess/${spaceId}/${userId}`)

export const adminAddSensorType = (sensorType) =>
  instance.post('/sensorTypes', sensorType)

export const adminUpdateSensorType = (sensorType) =>
  instance.put('/sensorTypes', sensorType)

export const adminDeleteSensorType = (sensorTypeId) =>
  instance.delete(`/sensorTypes/${sensorTypeId}`)

export const adminAddMachine = (machine) =>
  instance.post('/plugins/', machine)

export const adminUpdateMachine = (pluginId, plugin) =>
  instance.put(`/plugins/${pluginId}`, plugin)

export const adminDeleteMachine = (pluginId) =>
  instance.delete(`/plugins/${pluginId}`)

export const getSensorData = (sensorId, opts = {}) =>
  new Promise((resolve, reject) => {
    const {
      dateTimeStart,
      dateTimeEnd,
      interval
    } = opts
    instance.get(`/api/data/${sensorId}.json`, {
      headers: {
        accept: 'application/json'
      },
      params: {
        datetime_start: dateTimeStart || format(new Date(), 'yyyy-MM-dd 00:00:00'),
        datetime_end: dateTimeEnd || format(new Date(), 'yyyy-MM-dd 23:59'),
        interval
      }
    })
      .then((res) => resolve({
        res,
        ...res.data
      }))
      .catch(reject)
  })

export const getSensorInfo = (sensorId) =>
  new Promise((resolve, reject) => {
    instance.get(`/sensor/${sensorId}`)
      .then((res) => resolve({
        res,
        ...res.data
      }))
      .catch(reject)
  })
export const getSensorDiagnostics = (sensorId) =>
  new Promise((resolve, reject) => {
    instance.get(`/sensor/diagnostics/${sensorId}`)
      .then((res) => resolve({
        res,
        ...res.data
      }))
      .catch(reject)
  })

export const getSensorComfort = (sensorId) =>
  new Promise((resolve, reject) => {
    instance.get(`/sensor/stats/${sensorId}`)
      .then((res) => resolve({
        res,
        ...res.data
      }))
      .catch(reject)
  })

export const addSensorForUser = sensor =>
  instance.put('/user/sensors', sensor)

export const addSensorToSpace = (spaceId, sensorId) =>
  instance.post(`/spaces/${spaceId}/sensor`, sensorId)

export const resetSensorSpace = (sensorId) =>
  instance.delete(`/sensor/${sensorId}/space`)

export const deleteSensorFromSpace = sensorId =>
  instance.delete(`/sensor/${sensorId}/space`)

export const addPluginToSensor = (sensorId, PluginId) =>
  instance.post(`/sensors/${sensorId}/plugin`, PluginId)

export const updateSensorPlugin = (sensorPluginID, plugin) =>
  instance.patch(`/v3/sensorPlugin/${sensorPluginID}`, plugin)

export const forgotPwd = (email) =>
  new Promise((resolve, reject) => {
    instance.post('/user/forgot_password', email)
      .then((res) => {
        resolve({
          res
        })
      })
      .catch(reject)
  })

export const getSpaces = () => {
  const url = '/spaces'
  return instance.get(url)
}

export const getSpace = (spaceId) => {
  const url = `/spaces/${spaceId}`
  return instance.get(url)
}

export const getAllIssues = () =>
  instance.get('/su/issues')

export const addSpace = (space) =>
  instance.post('/spaces', space)

export const updateSpace = (space) =>
  instance.put('/spaces', space)

export const deleteSpace = (spaceId) =>
  instance.delete(`/spaces/${spaceId}`)

export const getSignedURL = (body) =>
  instance.post('/bucket/signed-url', body)

export const deleteImageFromBucket = (fileName) =>
  instance.delete(`/bucket/${fileName}`)

export const uploadImageToS3 = (url, file, callbackProgress) =>
  new Promise((resolve, reject) => {
    const options = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        callbackProgress(percentCompleted)
      },
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read'
      }
    }
    axios.put(
      url,
      file,
      options)
      .then((res) => {
        resolve(res)
      })
      .catch(reject)
  })

export const getPlugins = () =>
  instance.get('/plugins')

export const getPlugin = (id) =>
  instance.get(`/plugins/${id}`)
