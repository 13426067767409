import { Modal, Button as AntButton } from 'antd'
import { deleteSpace } from 'src/hooks'
import { WarningOutlined } from '@ant-design/icons'

import styled from 'styled-components'

const { confirm } = Modal

const DeleteFont = styled.span`
  text-decoration: underline;
`

const DeleteSensor = (props) => {
  const { space, handleRemove, setVisible } = props

  const handleClick = space => {
    const { id, name } = space
    confirm({
      title: 'Danger zone',
      icon: <WarningOutlined />,
      content: [
        <div key='info'>
          <p>{`Are you sure you want to delete ${name}`}</p>
          <ul>
            <li>This action is irreversible.</li>
            <li>Deleting a space will remove all associated information from our servers.</li>

          </ul>
        </div>
      ],
      okButtonProps: { size: 'large', type: 'primary' },
      cancelButtonProps: { size: 'large' },
      okText: `Yes, delete ${name}`,
      okType: 'danger',
      cancelText: 'Cancel',
      onOk () {
        deleteSpace(id)
          .then((res) => {
            handleRemove(res)
            setVisible(false)
          }).catch(() => {
          })
      }
    })
  }
  return (

    <AntButton type='link' danger size='large' key='cancel' onClick={() => handleClick(space)}>
      <DeleteFont>Delete</DeleteFont>
    </AntButton>

  )
}

export default DeleteSensor
