import { Breadcrumb } from 'antd'
import { HomeOutlined, UserOutlined } from '@ant-design/icons'
import { routes, navigate } from '@redwoodjs/router'
import styled from 'styled-components'
import MaxWidth from 'src/components/MaxWidth'
import * as c from 'src/constants'

const Wrap = styled.div`
  position: relative;
  z-index: 10;
`

const ChildContainer = styled.div`
  padding: 1.5rem 2rem;
  display: flex;
  margin-bottom: ${props => props.noMargin ? '0' : '1rem'};
  @media (max-width: ${c.SMALL_SCREEN}) {
  padding: ${props => props.noMargin ? '2rem 0.5rem' : '2rem'};
  }
`
const MainContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  & h1, h2, h3, h4, h5, h6, h7 {
    margin-bottom: 0;
  }
  padding: 0 0.5rem;
`

const ExtraContainer = styled.div`
  display: flex;
  align-items: center;
`

const BreadCrumbContainer = styled.div`
  padding: 0.5em 2em;
`

const AppHeader = (props) => {
  const {
    style: userStyle = {},
    children,
    BreadcrumbItems = [],
    extra,
    transparent,
    noMargin
  } = props
  const showBreadcrumbs = BreadcrumbItems.length > 0
  const style = transparent
    ? {}
    : {
      backgroundColor: 'white',
      boxShadow: '0 5px 20px rgba(0,0,0,0.15)'
    }
  return (
    <Wrap>
      <ChildContainer noMargin={noMargin} style={{ ...style, ...userStyle }}>
        <MaxWidth>
          <MainContainer>
            {children}
          </MainContainer>
          <ExtraContainer>
            {extra || null}
          </ExtraContainer>
        </MaxWidth>
      </ChildContainer>
      {showBreadcrumbs && (
        <MaxWidth>
          <BreadCrumbContainer>
            <Breadcrumb>
              {BreadcrumbItems.map((item, i) =>
                <Breadcrumb.Item key={i} onClick={(e) => navigate(item.route)}>
                  {item.title}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </BreadCrumbContainer>
        </MaxWidth>
      )}
    </Wrap>
  )
}

export default AppHeader
