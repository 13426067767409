import * as api from 'src/api'
import { routes, navigate } from '@redwoodjs/router'
import { message } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from 'src/context'
import { reduceToOne, mapEachObjKeys, filterObj, normalize, sortObj } from 'src/utils'
import { mapObjValues } from './utils'
import { getSpaceRelations } from 'src/libraries/spaces'
import { ACCESS_READ } from './constants'

const { localStorage } = window

// hooks
export const useCurrentState = () => {
  const context = useContext(Context)
  return {
    ...context
  }
}

export const useTheme = () => {
  const { client } = useCurrentState()
  return client
}

export const useSensor = (sensorId, opts = {}) => {
  const { config, units } = useCurrentState()
  const { dateTimeStart, dateTimeEnd, interval } = opts
  const [data, setData] = useState([]) // raw data from backend
  const [latestDataObj, setLatestDataObj] = useState({}) // the latest data point from the backend
  const [avgObj, setAvgObj] = useState([]) // object with average data for each field
  const [keyIndex, setKeyIndex] = useState([]) // sorted keys in order by weight and filtered by blacklist

  const [splitData, setSplitData] = useState([])
  const [normData, setNormData] = useState([]) // normalized data (0 - 100)
  const [minData, setMinData] = useState({}) // object containing the minimum values from the data fetched from backend
  const [maxData, setMaxData] = useState({}) // same as above but with maximum values

  const [radarLatestData, setRadarLatestData] = useState([]) // for radar chart, last data point normalized

  const [info, setInfo] = useState({}) // info about the sensor

  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  const resetData = () => {
    setError()
    setNormData([])
    setRadarLatestData([])
    setData([])
    setLatestDataObj({})
  }
  const updateSensorData = () => {
    resetData()
    setLoading(true)
    api.getSensorData(sensorId, opts)
      .then(({ results }) => setData(results))
      .catch(setError)
      .finally(() => setLoading(false))
  }
  const sortByWeight = (keys, values) => {
    const [a, b] = keys
    const unitA = units[a]
    const unitB = units[b]
    if (unitA && unitB) {
      return unitB.orderWeight - unitA.orderWeight
    } else {
      return -1
    }
  }
  useEffect(() => {
    if (sensorId) {
      updateSensorData()
      api.getSensorInfo(sensorId)
        .then((info) => setInfo(info.sensor))
    }
  }, [sensorId])
  useEffect(() => {
    if (sensorId) {
      resetData()
      updateSensorData()
    }
  }, [dateTimeStart, dateTimeEnd, interval])

  useEffect(() => {
    if (data && data.length > 0) {
      const lastRaw = data[data.length - 1]
      const filteredLast = filterObj(lastRaw, (value, key, i) => {
        const { sensorBlacklist } = config.display
        return sensorBlacklist.indexOf(key) === -1
      })
      const last = sortObj(filteredLast, sortByWeight)
      setKeyIndex(Object.keys(last))
      setLatestDataObj(last)
      const maxObj = reduceToOne(data, (key, acc, cur) =>
        acc[key]
          ? Math.max(cur[key], acc[key])
          : cur[key])
      const minObj = reduceToOne(data,
        (key, acc, cur) =>
          acc[key]
            ? Math.min(cur[key], acc[key])
            : cur[key])
      const avgObj = mapObjValues(
        reduceToOne(data, (key, acc, cur) => (acc[key] || 0) + cur[key]),
        (val, key) => key !== 'time'
          ? val / data.length
          : lastRaw.time
      ) // 💪 macho programming mvh victor
      const normData = mapEachObjKeys(data,
        (key, acc, cur) => {
          const unit = units[key]
          const [min, max] = (unit && unit.displayRange) || []
          return normalize(cur[key], max, min)
        }
      )
      const splitData = data.map(
        (obj) => mapObjValues(
          obj,
          (value, key) => {
            const unit = units[key]
            if (unit) {
              const { acceptableValues: av } = unit
              return {
                value: value > av.max
                  ? av.max
                  : value,
                overflow: value > av.max
                  ? value - av.max
                  : 0
              }
            } else {
              return value
            }
          }
        ))
      setSplitData(splitData)
      setAvgObj(avgObj)
      setMaxData(maxObj)
      setMinData(minObj)
      setNormData(normData)
    }
  }, [data, units])
  useEffect(() => {
    const rawLast = normData[normData.length - 1]
    if (rawLast) {
      const last = sortObj(rawLast, sortByWeight)
      const formatted = Object.keys(last)
        .filter((key) => !isNaN(last[key])) // remove non numeric values
        .map((key) => {
          const unit = units[key]
          const { displayRange, acceptableValues, comfortableValues } = unit
          const [min, max] = displayRange
          const normAcc = normalize(acceptableValues.max, max, min)
          const normComfort = normalize(comfortableValues.max, max, min)
          const current = last[key]
          return {
            unit: key,
            current: current,
            acceptable: unit && normAcc,
            comfortable: unit && normComfort
          }
        })
      setRadarLatestData(formatted)
    }
  }, [normData])
  return {
    ...info,
    data,
    radarLatestData,
    normData,
    minData,
    maxData,
    latestDataObj,
    loading,
    error,
    keyIndex,
    avgObj,
    splitData
  }
}

const MAX_RETRIES = 3

export const useSensors = () => {
  const [sensors, setSensors] = useState([])
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [tries, setTries] = useState(0)
  const [done, setDone] = useState(false)
  const refresh = () => setLastUpdate(new Date())
  const { user } = useUser()
  useEffect(() => {
    if (error && tries < MAX_RETRIES) {
      setTries(tries + 1)
      refresh()
    } else if (error) {
      setDone(true)
    }
  }, [error])
  useEffect(() => {
    setError(null)
    setLoading(true)
    api.getSensors(user.username)
      .then((res) => setSensors(res.sensors))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    refresh,
    sensors,
    done,
    error,
    loading
  }
}

export const addSensorForUser = (sensor, showMessage = true) => {
  return new Promise((resolve, reject) => {
    return api.addSensorForUser(sensor)
      .then((res) => {
        showMessage && message.success(res.message || 'Sensor was added')
        resolve(res.data)
      })
      .catch((err) => {
        showMessage && message.error(err.response.data.error || 'Unable to add sensor')
        reject(err)
      })
  })
}

export const addSensorToSpace = (spaceId, sensorId) => {
  return new Promise((resolve, reject) => {
    const sensorObject = { sensorId }
    return api.addSensorToSpace(spaceId, sensorObject)
      .then((res) => {
        message.success(res.message || 'Sensor was added to Space')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add sensor to Space')
        reject(err)
      })
  })
}

export const resetSensorSpace = (sensorId) => {
  return new Promise((resolve, reject) => {
    return api.resetSensorSpace(sensorId)
      .then((res) => {
        message.success(res.message || 'Sensor was removed from Space')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to remove sensor from Space')
        reject(err)
      })
  })
}

export const addPluginToSensor = (sensorId, plugin) => {
  return new Promise((resolve, reject) => {
    return api.addPluginToSensor(sensorId, plugin)
      .then(res => {
        message.success(res.message || 'Plugin added to sensor')
        resolve(res.data)
      }).catch(err => {
        message.error(err.response.data.message || 'Unable to add plugin to sensor')
        reject(err)
      })
  })
}

export const updateSensorPlugin = (sensorPluginID, plugin) => {
  return new Promise((resolve, reject) => {
    return api.updateSensorPlugin(sensorPluginID, plugin)
      .then(res => {
        message.success(res.message || 'Plugin updated')
        resolve(res.data)
      }).catch(err => {
        message.error(err.response.data.message || 'Unable to update plugin')
        reject(err)
      })
  })
}

export const deleteSensorFromSpace = deviceId => {
  return new Promise((resolve, reject) => {
    return api.deleteSensorFromSpace(deviceId)
      .then((res) => {
        message.success(res.message || 'Sensor was deleted')
        resolve(res)
      })
      .catch((err) => {
        message.error(err.message || 'Unable to delete sensor')
        reject(err)
      })
  })
}

export const storeUnitsSettings = (units) => {
  localStorage.setItem('units', JSON.stringify(units))
  message.success('update successfully')
}

export const useIssues = (opts = {}) => {
  const { code } = opts
  const [issues, setIssues] = useState()
  const [issuesByType, setIssuesByType] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  useEffect(() => {
    setLoading(true)
    api.getAllIssues()
      .then((res) => {
        if (code) return setIssues(res.data.filter((issue) => issue.code === code))
        setIssues(res.data)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])
  useEffect(() => {
    if (!issues) return
    const byType = issues.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.category]: acc[cur.category]
          ? [...acc[cur.category], cur]
          : [cur]
      }
    }, {})
    setIssuesByType(byType)
  }, [issues])
  return {
    issues,
    loading,
    issuesByType,
    error
  }
}

export const useUser = () => {
  const cachedUser = localStorage.getItem('user')
  const defaultUser = cachedUser
    ? JSON.parse(cachedUser)
    : {}
  const [user, setUser] = useState({
    tenants: [],
    ...defaultUser,
    admin: defaultUser?.admin,
    superAdmin: defaultUser?.admin
  })
  const hasPermission = useCallback((tenantId, minPermission = ACCESS_READ) => !!(
    user.admin ||
    user?.tenants.find(({ id, permission }) => id === tenantId && permission >= minPermission)
  ), [user])

  return {
    user,
    hasPermission
  }
}

// ui related functions, mini hooks
export const login = (username, password) => {
  api.login(username, password)
    .then(({ user }) => {
      localStorage.setItem('user', JSON.stringify(user))
      navigate(routes.app())
      message.success('Sign in successfully')
    })
    .catch((err) => {
      console.log(err.response.data.error)
      message.error(err.response.data.error || 'Wrong username / password')
    })
}

export const updatePasswordWithKey = (payload) =>
  new Promise((resolve, reject) => {
    api.updatePasswordWithKey(payload)
      .then(res => {
        message.success('Successfully update password')
        resolve(res)
      })
      .catch(err => {
        console.log(err.response.data.error)
        message.error(err.response.data.error || 'Something went wrong updating your password')
        reject(err)
      })
  })

export const logout = () => {
  api.logout()
    .then(() => {
      localStorage.removeItem('user')
      navigate(routes.login())
    })
    .catch((err) => message.error(err.message || 'Unable to logout?!'))
}

export const forgotPwd = (email) => {
  return api.forgotPwd(email)
}

export const useSpaces = () => {
  const [spaces, setSpaces] = useState([])
  const [loading, setLoading] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.getSpaces()
      .then((response) => setSpaces(response.data))
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    loading,
    spaces,
    refresh
  }
}

const merge = (a, b) =>
  Object.entries(a)
    .reduce((acc, [key, value]) => ({
      [key]: value != null
        ? value
        : b[key],
      ...acc
    }), {})

export const useMeasurements = (spaceId, opts = {}) => {
  const [measurements, setMeasurements] = useState() // measurements from the sensor
  const [allMeasurements, setAllMeasurements] = useState() // includes plugin measurements too, grouped by timestamp
  const [plugins, setPlugins] = useState() // plugins that have measurements in the response from api
  const [existingKeys, setExistingKeys] = useState() // keys that contains non null values
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    if (spaceId != null) {
      setLoading(true)
      api.getMeasurementsForSpace(spaceId, opts)
        .then((msrs) => {
          const onlySensorMsrmts = msrs.filter((msr) => msr.temp != null) // remove objects without sensor data
          const allMeasurementsObj = msrs.reduce((acc, cur) => ({ // group by timestamp
            ...acc,
            [cur.time]: acc[cur.time]
              ? merge(acc[cur.time], cur)
              : cur
          }), {})
          const allMeasurements = Object.values(allMeasurementsObj) // convert grouped to array
          const batch = msrs && msrs.length && msrs.length > 0
            ? merge(merge(msrs[0], msrs[1]), msrs[2])
            : {}
          const existingKeysObj = Object.keys(batch)
            .reduce((acc, cur) => ({ // loop through each key
              ...acc,
              [cur]: msrs.find(
                (msr) => msr[cur] != null
              ) != null // find at least one measurement not null, set true / false
            }), {})
          const existingKeys = Object.entries(existingKeysObj)
            .filter(([key, value]) => value === true)
            .map(([key]) => key)
          const pluginsObj = msrs.reduce((acc, cur) => ({
            ...acc,
            [cur.pluginUid]: { // store each uid in own object with more info about plugin
              pluginUid: cur.pluginUid,
              pluginName: cur.pluginName,
              keys: [...new Set([...Object.entries(cur)
                .reduce((acc, [key, value]) =>
                  value != null // create list of keys with values
                    ? [...acc, key] // add key to list
                    : acc
                , []), ...(acc[cur.pluginUid]?.keys || [])])] // keep old ones
            }
          }), {})
          setPlugins(pluginsObj)
          setExistingKeys(existingKeys)
          setMeasurements(onlySensorMsrmts)
          setAllMeasurements(allMeasurements)
        }, opts)
        .catch(setError)
        .finally(() => setLoading(false))
    }
  }, [spaceId, opts.dateFrom])
  return {
    existingKeys,
    measurements,
    allMeasurements,
    plugins,
    loading,
    error
  }
}

export const useSuSpace = (spaceId) => {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [space, setSpace] = useState()
  const [algos, setAlgos] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  const updateSpaceAlgo = (spaceAlgoId, relationData) =>
    new Promise((resolve, reject) => {
      const hide = message.loading('Updating algo for space..')
      api.updateSpaceAlgorithm(spaceAlgoId, relationData)
        .then(() => {
          message.success('Successfully updated spaceAlgo')
          refresh()
          resolve()
        })
        .catch((err) => {
          message.error(err.message)
          reject(err)
        })
        .finally(() => hide())
    })

  const addSpaceAlgo = (algorithmId, relationData = {}) =>
    new Promise((resolve, reject) => {
      const hide = message.loading('Adding algo to space..')
      api.addSpaceAlgo(spaceId, algorithmId, relationData)
        .then(() => {
          message.success('Successfully added to space')
          resolve()
        })
        .catch((err) => {
          message.error(err.message)
          reject(err)
        })
        .finally(() => hide())
    })
  useEffect(() => {
    setLoading(true)
    setError()
    Promise.all([
      api.getAlgorithmsForSpace(spaceId)
        .then((res) => setAlgos(res.data)),
      api.getSpace(spaceId)
        .then((res) => setSpace(res.data))
    ])
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    space: {
      ...space,
      algorithms: algos || []
    },
    loading,
    error,
    addSpaceAlgo,
    updateSpaceAlgo,
    refresh
  }
}

export const useSpace = (spaceId) => {
  const { spaces, loading, refresh: refreshSpaces } = useSpaces()
  const [space, setSpace] = useState()
  const [_loading, setLoading] = useState(loading)
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = refreshSpaces
  useEffect(() => {
    setLoading(loading)
    if (!loading) {
      const space = spaces.find((space) => space.id === spaceId)
      const {
        children,
        parents,
        rooms,
        floors,
        sensors,
        latestData
      } = getSpaceRelations(spaces, spaceId)
      if (space) {
        setSpace({
          ...space,
          children,
          parents,
          sensors,
          rooms,
          floors,
          latestData
        })
      }
    }
  }, [loading, spaces, lastUpdate])
  return {
    space: space || {},
    spaces,
    refresh,
    loading: _loading
  }
}
export const addSpace = (newSpace) => {
  return new Promise((resolve, reject) => {
    return api.addSpace(newSpace)
      .then((res) => {
        message.success(res.message || 'Space was added')
        resolve(res)
      })
      .catch((err) => {
        message.error(err.message || 'Unable to add space')
        reject(err)
      })
  })
}
export const updateSpace = (updatedSpace) => {
  return new Promise((resolve, reject) => {
    return api.updateSpace(updatedSpace)
      .then((res) => {
        message.success(res.message || 'Space was updated')
        resolve(res)
      })
      .catch((err) => {
        message.error(err.message || 'Unable to update space')
        reject(err)
      })
  })
}
export const deleteSpace = (spaceId) => {
  return new Promise((resolve, reject) => {
    return api.deleteSpace(spaceId)
      .then((res) => {
        message.success(res.message || 'Space was deleted')
        resolve(res.data[0])
      })
      .catch((err) => {
        message.error(err.message || 'Unable to delete space')
        reject(err)
      })
  })
}
export const getUrlBucket = (file) => {
  return new Promise((resolve, reject) => {
    const { uid } = file
    return api.getSignedURL(file)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const deleteImageUrl = (fileUrl, showFeedback = true) => {
  return new Promise((resolve, reject) => {
    const filenameToRemove = fileUrl.split('/').slice(-1)[0]
    return api.deleteImageFromBucket(filenameToRemove)
      .then((res) => {
        showFeedback && message.success(res.message || 'Image deleted')
        resolve(res.data)
      })
      .catch((err) => {
        showFeedback && message.error(err.message || 'Unable to deleted image')
        reject(err)
      })
  })
}
export const uploadImageBucket = (url, file, key, callbackProgress) => {
  return new Promise((resolve, reject) => {
    const callback = (value) => {
      callbackProgress(value)
    }
    return api.uploadImageToS3(url, file, callback)
      .then((res) => {
        message.success({ content: (res.message || 'Image was uploaded'), key })
        resolve(res.config.url)
      })
      .catch((err) => {
        message.error({ content: (err.message || 'Unable to uploaded image'), key })
        reject(err)
      })
  })
}
export const useSensorPlugins = () => {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [sensorPlugins, setSensorPlugins] = useState()
  useEffect(() => {
    setLoading(true)
    api.getSensorPlugins()
      .then((res) => {
        setSensorPlugins(res.data)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])
  return {
    loading,
    error,
    sensorPlugins
  }
}

export const usePlugins = () => {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [plugins, setPlugins] = useState()
  useEffect(() => {
    setLoading(true)
    api.getPlugins()
      .then((res) => {
        setPlugins(res.data)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])
  return {
    loading,
    error,
    plugins
  }
}
export const getPlugins = () => {
  return new Promise((resolve, reject) => {
    return api.getPlugins()
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getPlugin = (id) => {
  return new Promise((resolve, reject) => {
    return api.getPlugin(id)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const useSensorTypes = () => {
  const [sensorTypes, setSensorTypes] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.getSensorTypes()
      .then((types) => setSensorTypes(types))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    sensorTypes,
    loading,
    error,
    refresh
  }
}

const useTriad = (getDataPromise, initValue = null) => {
  const [data, setData] = useState(initValue)
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    getDataPromise()
      .then((data) => setData(data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    data,
    loading,
    error,
    refresh
  }
}

export const useLogs = () => {
  const {
    data: logs,
    ...defaults
  } = useTriad(() =>
    new Promise((resolve, reject) => {
      api.getLogs()
        .then((res) => resolve(res.data))
        .catch(reject)
    }), [])
  return {
    ...defaults,
    logs
  }
}

export const useTemperatureControl = (spaceId) => {
  const ALGO_KEY = 'temperatureControlA'
  const [intervals, setIntervals] = useState([])
  const [days, setDays] = useState([])
  const [configurableSettings, setConfigurableSettings] = useState()
  const [intervalKeys, setIntervalKeys] = useState([])
  const [restKeys, setRestKeys] = useState([])
  const [response, setResponse] = useState()
  const [temperatures, setTemperatures] = useState() // structured for component
  // format body according to api
  const formatToApi = (componentPayload) => {
    const flatArr = componentPayload.reduce((acc, cur) => [...acc, ...cur], [])
    if (flatArr.length !== intervalKeys.length) {
      message.warn('Different lengths input / output in Temperature Control', 2)
    }
    const structured = intervalKeys.reduce(
      (acc, key, i) => ({
        ...acc,
        [key]: {
          ...configurableSettings[key],
          value: flatArr[i]
        }
      }), {})
    return structured
  }
  // format to temperature controls component prefered format
  const getPayload = () => {

  }
  const save = (componentPayload) =>
    new Promise((resolve, reject) => {
      const apiPayload = formatToApi(componentPayload)
      const nonIntervalSettings = restKeys.reduce((acc, key) => ({
        ...acc,
        [key]: {
          ...configurableSettings[key],
          value: configurableSettings[key].default
        }
      }), {})
      const userSettings = {
        user: {
          ...apiPayload,
          ...nonIntervalSettings
        }
      }
      const jsonStr = JSON.stringify(userSettings)
      const payload = {
        userSettings: jsonStr
      }
      api.updateOrAddAlgoByKeyForSpace(ALGO_KEY, spaceId, payload)
        .then((res) => {
          message.success('Successfully set temperature control! 🚀')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.message)
          console.log('hooks.useTempControl.save', err)
          reject(err)
        })
    })
  const parseJSONSettings = (jsonStr) => {
    const configurableSettings = JSON.parse(jsonStr)
    const { user = {} } = configurableSettings
    setConfigurableSettings(user)
    const objKeys = Object.keys(user)
    const sorted = objKeys.reduce((acc, key) => {
      const isInterval = key.indexOf('-') > -1
      return {
        intervalKeys: isInterval
          ? [...acc.intervalKeys, key]
          : acc.intervalKeys,
        rest: !isInterval
          ? [...acc.rest, key]
          : acc.rest
      }
    }, { intervalKeys: [], rest: [] })
    const intervalKeys = sorted.intervalKeys
    console.log(sorted.rest, intervalKeys)
    setRestKeys(sorted.rest)
    setIntervalKeys(intervalKeys)
    const structured = intervalKeys.reduce((acc, interval) => {
      const [dayStr, rangeStart, rangeEnd] = interval.split('-')
      const range = [rangeStart, rangeEnd].join('-')
      const day = parseInt(dayStr, 10)
      const defaultTemp = 23
      const tempValue = user[interval].value || defaultTemp
      return {
        days: acc.days
          ? [...acc.days, day]
          : [day],
        intervals: acc.intervals
          ? [...acc.intervals, range]
          : [range],
        temperatures: acc.temperatures
          ? [...acc.temperatures, tempValue]
          : [tempValue]
      }
    }, {})
    // remove duplicates
    const days = [...new Set(structured.days)]
    const intervals = [...new Set(structured.intervals)]
    const temperatures = days.reduce((acc, _, i) => {
      const startIndex = i * intervals.length
      const endIndex = (i + 1) * intervals.length
      const part = structured.temperatures.slice(startIndex, endIndex)
      return [...acc, part]
    }, [])
    return {
      days,
      intervals,
      temperatures
    }
  }
  const setSettings = (settingsObj) => {
    const { days, intervals, temperatures } = settingsObj
    setDays(days)
    setIntervals(intervals)
    setTemperatures(temperatures)
    console.log(temperatures)
  }

  useEffect(() => { // get info from backend
    api.getAlgoByKeyForSpace(ALGO_KEY, spaceId)
      .then((res) => {
        if (res.status === 200) {
          const { data } = res
          setResponse(data)
          const settings = parseJSONSettings(data.userSettings)
          setSettings(settings)
        }
      })
      .catch((err) => {
        console.log(err.message)
        const res = err.response
        if (res.status === 404) {
          api.getAlgoByKey(ALGO_KEY)
            .then((res) => {
              const { data } = res
              setResponse(data)
              const settings = parseJSONSettings(data.configurableSettings)
              setSettings(settings)
            })
            .catch((err) => message.error(err.message))
        } else {
          message.error(err.message)
        }
      })
  }, [spaceId])
  return {
    days,
    configurableSettings,
    intervals,
    save,
    temperatures
  }
}

export const useAlgorithms = () => {
  const [algorithms, setAlgorithms] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.getAllAlgorithms()
      .then((res) => setAlgorithms(res.data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    algorithms,
    loading,
    error,
    refresh
  }
}
export const useAlgorithm = (algorithmId) => {
  const [algorithm, setAlgorithm] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  const updateAlgorithm = (algorithmId, newData) => {
    const hide = message.loading('Updating algorithm...')

    api.updateAlgorithm(algorithmId, newData)
      .then(() => {
        hide()
        message.success('Successfully updated algorithm.')
        setAlgorithm({
          id: algorithmId,
          ...newData
        })
      })
      .catch(setError)
      .finally(() => hide())
  }
  const addAlgorithm = (algo) => {
    const hide = message.loading('Adding algorithm...')
    api.addAlgorithm(algo)
      .then(() => {
        hide()
        message.success('Successfully added algorithm')
        setAlgorithm(algo)
      })
      .catch(setError)
      .finally(() => hide())
  }
  useEffect(() => {
    setLoading(true)
    api.getAlgorithm(algorithmId)
      .then((res) => setAlgorithm(res.data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    algorithm,
    loading,
    error,
    refresh,
    updateAlgorithm,
    addAlgorithm
  }
}

/// / Admin functions
export const UseSensorStats = (id) => {
  const [sensorStats, setSensorStats] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.getSensorStats(id)
      .then((res) => setSensorStats(res.data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    sensorStats,
    loading,
    error,
    refresh
  }
}

export const UseAdminAllSensors = () => {
  const [sensors, setSensors] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.adminGetAllSensors()
      .then((res) => setSensors(res.data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    sensors,
    loading,
    error,
    refresh
  }
}

export const useAdminSensor = (id) => {
  const [sensor, setSensor] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  useEffect(() => {
    setLoading(true)
    api.adminGetSensor(id)
      .then((res) => setSensor(res.data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])
  return {
    sensor,
    loading,
    error
  }
}

export const UseAdminAllUsers = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.adminGetAllUsers()
      .then((res) => setUsers(res.data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    users,
    loading,
    error,
    refresh
  }
}

export const UseAdminUser = (userId) => {
  const [user, setUser] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.adminGetUser(userId)
      .then((res) => setUser(res.data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    user,
    loading,
    error,
    refresh
  }
}

export const UseUserWithSpaceAccess = (spaceId) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.adminGetUserWithSpaceAccess(spaceId)
      .then((res) => setUsers(res.data))
      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    users,
    loading,
    error,
    refresh
  }
}

export const useAdminAllSpaces = (edit) => {
  const [spaces, setSpaces] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [update, setUpdate] = useState(!edit)
  const refresh = () => setUpdate(true)

  useEffect(() => {
    if (update) {
      setLoading(true)
      api.adminGetAllSpaces()
        .then((res) => setSpaces(res.data))
        .catch(setError)
        .finally(() => {
          setLoading(false)
          setUpdate(false)
        })
    }
  }, [update])
  return {
    spaces,
    loading,
    error,
    refresh
  }
}

export const UseAdminAllMachines = () => {
  const [machines, setMachines] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const refresh = () => setLastUpdate(new Date())
  useEffect(() => {
    setLoading(true)
    api.getPlugins()
      .then((res) => {
        setMachines(res.data)
      })

      .catch(setError)
      .finally(() => setLoading(false))
  }, [lastUpdate])
  return {
    machines,
    loading,
    error,
    refresh
  }
}

export const adminAddSensor = (sensor) => {
  return new Promise((resolve, reject) => {
    return api.adminAddSensor(sensor)
      .then((res) => {
        message.success(res.message || 'Added sensor')
        resolve(res.data)
      })
      .catch((err) => {
        const errData = err.response.data
        const { errors = [] } = errData
        message.error(errors.map(error => error.message) || 'Unable to add sensor')
        reject(err)
      })
  })
}

export const adminUpdateSensor = (sensor) => {
  return new Promise((resolve, reject) => {
    return api.adminUpdateSensor(sensor)
      .then((res) => {
        message.success(res.message || 'Updated sensor')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to update sensor')
        reject(err)
      })
  })
}

export const adminAddSensorToUser = (payload) => {
  return new Promise((resolve, reject) => {
    return api.adminAddSensorToUser(payload)
      .then((res) => {
        message.success(res.message || 'Added sensor to user')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add sensor to user')
        reject(err)
      })
  })
}

export const adminRemoveSensorFromUser = (userId, sensorId) => {
  return new Promise((resolve, reject) => {
    return api.adminRemoveSensorFromUser(userId, sensorId)
      .then((res) => {
        message.success(res.message || 'Removed sensor from user')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to remove sensor from user')
        reject(err)
      })
  })
}

export const adminDeleteSensor = (sensor) => {
  return new Promise((resolve, reject) => {
    const { id } = sensor
    return api.adminDeleteSensor(id)
      .then((res) => {
        message.success(res.message || 'Deleted sensor')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to delete sensor')
        reject(err)
      })
  })
}

export const adminAddUser = (user) => {
  return new Promise((resolve, reject) => {
    console.log('adminAddUser', user)
    return api.adminAddUser(user)
      .then((res) => {
        message.success(res.message || 'Added user')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add user')
        reject(err)
      })
  })
}

export const adminUpdateUser = (user) => {
  return new Promise((resolve, reject) => {
    return api.adminUpdateUser(user)
      .then((res) => {
        message.success(res.message || 'Updated user')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to update user')
        reject(err)
      })
  })
}

export const adminDeleteUser = (user) => {
  return new Promise((resolve, reject) => {
    console.log('user', user)
    const { id } = user
    return api.adminDeleteUser(id)
      .then((res) => {
        message.success(res.message || 'Deleted user')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to delete user')
        reject(err)
      })
  })
}

export const adminAddUserToSpace = (payload) => {
  return new Promise((resolve, reject) => {
    return api.adminAddUserToSpace(payload)
      .then((res) => {
        message.success(res.message || 'Added user')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add user')
        reject(err)
      })
  })
}

export const adminRemoveUserFromSpace = (spaceId, userId) => {
  return new Promise((resolve, reject) => {
    return api.adminRemoveUserFromSpace(spaceId, userId)
      .then((res) => {
        message.success(res.message || 'Removed user')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to remove user')
        reject(err)
      })
  })
}

export const adminAddSensorType = (SensorType) => {
  return new Promise((resolve, reject) => {
    return api.adminAddSensorType(SensorType)
      .then((res) => {
        message.success(res.message || 'Added SensorType')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add SensorType')
        reject(err)
      })
  })
}

export const adminUpdateSensorType = (SensorType) => {
  return new Promise((resolve, reject) => {
    console.log('SensorType hooks', SensorType)
    return api.adminUpdateSensorType(SensorType)
      .then((res) => {
        message.success(res.message || 'Updated SensorType')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to update SensorType')
        reject(err)
      })
  })
}

export const adminDeleteSensorType = (SensorType) => {
  return new Promise((resolve, reject) => {
    const { id } = SensorType
    return api.adminDeleteSensorType(id)
      .then((res) => {
        message.success(res.message || 'Deleted SensorType')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to delete SensorType')
        reject(err)
      })
  })
}

export const adminAddMachine = (machine) => {
  return new Promise((resolve, reject) => {
    return api.adminAddMachine(machine)
      .then((res) => {
        message.success(res.message || 'Added machine')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error.message || 'Unable to add machine')
        reject(err)
      })
  })
}

export const adminUpdateMachine = (machineID, machine) => {
  return new Promise((resolve, reject) => {
    return api.adminUpdateMachine(machineID, machine)
      .then(res => {
        message.success(res.message || 'Machine updated')
        resolve(res.data)
      }).catch(err => {
        message.error(err.response.data.message || 'Unable to update machine')
        reject(err)
      })
  })
}

export const adminDeleteMachine = (machine) => {
  return new Promise((resolve, reject) => {
    const { id } = machine
    return api.adminDeleteMachine(id)
      .then((res) => {
        message.success(res.message || 'Deleted machine')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to delete machine')
        reject(err)
      })
  })
}
