import { Link, routes, navigate } from '@redwoodjs/router'
import { Avatar, Dropdown, Menu } from 'antd'
import { UserOutlined, LogoutOutlined, SettingOutlined, AppstoreOutlined, DashboardOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import * as hooks from 'src/hooks'

const Container = styled.div`
  height: ${props => props.dashboard ? 'inherit' : '100%'};
  & > :hover {
    transition: all 0.3s;
    background-color: #f5f5f5;
  }
`

const AvatarContainer = styled.div`
  height: 100%;
  padding: 1em;
  display: flex;
  & a {
    display: flex;
  justify-content: center;
  align-items: center;
  }

  @media (max-width: 768px) {
    & .ant-avatar.ant-avatar-sm {
      margin-right: 0 !important;
    }

    & .desktop-content {
      display: none;
    }
  }
`

const DropDownMenuContainer = styled.div`
 box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`

const AvatarStyled = styled(Avatar)`
  vertical-align: 'middle';
  margin-right: 0.5em;
  background: ${props => props.theme.primaryColor || '#7297ff'};
`

const avatarMenu = ({ dashboard, admin }) => {
  const handleSignOut = hooks.logout
  return (
    <DropDownMenuContainer>
      <Menu>
        {dashboard
          ? admin && (
            <Menu.Item onClick={() => navigate(routes.dashboard())}>
              <>
                <DashboardOutlined /> Dashboard
              </>
            </Menu.Item>)
          : (
            <Menu.Item onClick={() => navigate(routes.app())}>
              <>
                <AppstoreOutlined /> App
              </>
            </Menu.Item>)}
        {/* <Menu.Item disabled>
          <>
            <SettingOutlined /> Settings
          </>
        </Menu.Item> */}
        <Menu.Divider />
        <Menu.Item onClick={handleSignOut}>
          <>
            <LogoutOutlined /> sign out
          </>
        </Menu.Item>
      </Menu>
    </DropDownMenuContainer>
  )
}

const UserAvatar = (props) => {
  const { user, dashboard } = props
  const { client } = hooks.useCurrentState()

  return (
    <Container dashboard={dashboard}>
      <Dropdown
        overlay={avatarMenu(props)}
      >
        <AvatarContainer>
          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            <AvatarStyled
              size={dashboard ? 'medium' : 'small'}
              icon={<UserOutlined />}
            />
            <span className='desktop-content'> {user.username}</span>
          </a>
        </AvatarContainer>
      </Dropdown>
    </Container>
  )
}

export default UserAvatar
