import { useState, useEffect } from 'react'
import {
  Alert,
  Select,
  Card,
  message,
  Button as AntButton,
  Modal,
  Space
} from 'antd'
import styled from 'styled-components'
import Button, { BackButton } from 'src/components/Button'
import { WarningOutlined } from '@ant-design/icons'
import { addSensorToSpace, resetSensorSpace } from 'src/hooks'

const { confirm } = Modal

const { Option, OptGroup } = Select

const DANGER = 'DANGER'

const BottomWrapper = styled.div`
  margin-top: 0.5rem;
`

const AdminAssignSpaceSensor = (props) => {
  const {
    sensor,
    buildings = [],
    floors = [],
    rooms = []
  } = props
  const { spaceId } = sensor
  const [selectedID, setSelectedId] = useState(spaceId)

  const handleSelectItem = (value) => {
    setSelectedId(value)
  }

  const ValidateSpaceChange = () => {
    if (selectedID || selectedID === DANGER) {
      if (selectedID === DANGER) {
        showConfirm(spaceId)
      } else {
        SubmitSpaceChange()
      }
    } else {
      message.error('You need to assign the sensor to a space to update')
    }
  }

  const showConfirm = (spaceId) => {
    const { id, name } = sensor
    confirm({
      title: 'Danger zone',
      icon: <WarningOutlined />,
      content: [
        <div key='info'>
          <p>{`Are you sure you you want to remove ${name} from all spaces?`}</p>
          <ul>
            <li>This action is irreversible.</li>
            <li>This will remove the sensor from its space.</li>
          </ul>
        </div>
      ],
      okButtonProps: { size: 'large', type: 'primary' },
      cancelButtonProps: { size: 'large' },
      okText: 'Yes, remove',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk () {
        resetSensorSpace(id)
      },
      onCancel () {
        message.info('Sensor space not updated')
      }
    })
  }

  const SubmitSpaceChange = () => {
    const { id } = sensor
    addSensorToSpace(selectedID, id)
      .then((res) => {
      }).catch(() => {})
  }

  const roomsWithWeirdParent = rooms.filter((room) => {
    const buildingParent = buildings.find((build) => build.id === room.parentSpaceId)
    return buildingParent != null || room.parentSpaceId == null
  })

  return (
    <Card>
      <div>
        <Select
          style={{ width: '100%' }}
          showSearch
          allowClear
          placeholder='Assign to a room'
          optionFilterProp='rooms'
          defaultValue={spaceId || undefined}
          onSelect={(value, item) => handleSelectItem(value, item)}
          onClear={() => {
            setSelectedId()
          }}
          filterOption={(input, option) =>
            option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <OptGroup key='danger' label='DANGER ZONE'>
            <Option style={{ color: 'red' }} key='danger' value={DANGER}>
              <span><i className='fas fa-exclamation-triangle' />&nbsp;Unassign sensor from room</span>
            </Option>
          </OptGroup>
          {buildings.map(building => {
            return floors.map(floor => {
              if (floor.parentSpaceId === building.id) {
                return (
                  <OptGroup key={floor.id} label={`${building.name} - ${floor.name}`}>
                    {rooms.map((room, i) => {
                      if (room.parentSpaceId === floor.id) {
                        return <Option key={room.id} value={room.id}>{room.name}</Option>
                      }
                    })}
                  </OptGroup>
                )
              }
            }
            )
          })}
          <OptGroup key='unassigned-rooms' label='Rooms without floor'>
            {roomsWithWeirdParent.map((room, i) => {
              return <Option key={room.id} value={room.id}>{room.name}</Option>
            })}
          </OptGroup>
        </Select>
        <BottomWrapper>
          <Button onClick={() => ValidateSpaceChange()}>Save</Button>
        </BottomWrapper>
      </div>
    </Card>
  )
}

export default AdminAssignSpaceSensor
