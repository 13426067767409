import { useState, useEffect, useCallback } from 'react'
import {
  Modal,
  Space,
  Form,
  Input,
  Button as AntButton,
  Radio,
  Upload,
  message,
  Progress
} from 'antd'
import {
  InboxOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  WarningOutlined
} from '@ant-design/icons'
import {
  addSpace,
  useCurrentState,
  getUrlBucket,
  uploadImageBucket,
  deleteSpace,
  updateSpace,
  deleteImageUrl
} from 'src/hooks'
import Button from 'src/components/Button'
import DeleteSpace from 'src/components/DeleteSpace'

import * as c from '../../constants'
import { roomStyles } from '../../libraries/spaces'

import styled, { css } from 'styled-components'

const FormContainer = styled.div`
  padding: 2em 3em;
  & .ant-row.ant-form-item {
    justify-content: center;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
`

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 1rem;
  }
`

const StyledDragger = styled(Upload.Dragger)`
  width: 100%;
`

const PreviewImage = styled.img`
  /* flex: 1; */
  width: 128px;
  height: 128px;
  border-radius: 1rem;
  box-shadow: 0px 10px 25px rgba(0,0,0,0.2);
  object-fit: cover;
  ${props => props.isEmpty && css`
    background-color: gray;
    outline: none;
    border: none;
  `}
`

const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`

const ModalContainer = styled.div``

const FileListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em;
`

const FileNameWrap = styled.div`
  display: flex;
  & span {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 0.5em;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`

const DeleteWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DeleteFont = styled.span`
  text-decoration: underline;
`

const ButtonContainer = styled.div`
  padding: 0.5em;
`

const AddSpace = (props) => {
  const {
    spaceType,
    parentSpace,
    visible,
    setVisible,
    onDone: handleDone,
    onUpdate: handleUpdate,
    onRemove: handleRemove,
    editSpace,
    setEditSpace
  } = props
  const { client } = useCurrentState()
  const { antd } = client

  const [loading, setLoading] = useState(false)
  const [spaceName, setSpaceName] = useState('')
  const [urlToImage, setUrlToImage] = useState()
  const [fileList, setFileList] = useState([])
  const [progressUpload, setProgressUpload] = useState()
  const [uploadStatus, setUploadStatus] = useState()
  const [previewBlob, setPreviewBlob] = useState()

  const handleSubmit = (e) => {
    setLoading(true)
    if (editSpace) {
      const { id } = editSpace
      const newSpace = {
        id,
        parentSpaceId: parentSpace,
        coverImage: urlToImage,
        ...e
      }
      updateSpace(newSpace)
        .then((res) => {
          setLoading(false)
          setVisible(false)
          handleUpdate({
            ...newSpace,
            ...res.data,
            accessLevel: c.ACCESS_WRITE
          })
          handleUpdate(res.data)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      const newSpace = {
        parentSpaceId: parentSpace,
        coverImage: urlToImage,
        ...e
      }
      addSpace(newSpace)
        .then((res) => {
          setLoading(false)
          setVisible(false)
          handleDone({
            ...newSpace,
            ...res.data,
            accessLevel: c.ACCESS_WRITE
          })
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const handleCancel = (e) => {
    setVisible(false)
    setPreviewBlob()
    if (editSpace) {
      setEditSpace()
    }
    if (urlToImage) {
      deleteImageUrl(urlToImage, false).then((res) => {
        setUrlToImage('')
        setFileList([])
      })
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const uploadCheck = (file) => {
    if (fileList && fileList.length < 1) {
      return true
    } else {
      message.info(`You can only upload one image for each ${spaceName}`)
      return false
    }
  }

  const clickedDelete = () => {
    setLoading(true)
    deleteImageUrl(urlToImage)
      .then((res) => {
        const tempList = []
        setUrlToImage('')
        setFileList(tempList)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const callbackProgress = (value) => {
    setProgressUpload(value)
  }

  const getSignedUrl = async (options) => {
    const { onSuccess, onError, file } = options
    const { name, uid, type } = file
    const fileProps = { name, uid, type }
    const key = uid
    setUploadStatus(true)
    setProgressUpload(1)
    message.loading({ content: `${name} file uploading.`, key, duration: 0 })
    try {
      getUrlBucket(fileProps).then((res) => {
        const { File } = window
        const newFile = new File([file], res.fileName)
        return uploadImageBucket(res.url, newFile, key, callbackProgress)
          .then((res) => {
            const parts = res.split('?')
            const ImageUrl = parts[0]
            setFileList([{ name, ImageUrl }])
            setUrlToImage(ImageUrl)
            setProgressUpload(undefined)
            onSuccess('OK')
          })
          .catch((err) => {
            setUploadStatus(false)
            message.loading({
              content: `${name} file uploading.`,
              key,
              duration: 0.001
            })
            onError(err)
          })
      })
    } catch (err) {
      setUploadStatus(false)
      message.loading({
        content: `${name} file uploading.`,
        key,
        duration: 0.001
      })
      onError(err)
    }
  }

  const getSpaceType = (type) => {
    switch (type) {
      case c.TYPE_BUILDING:
        return 'Building'
      case c.TYPE_FLOOR:
        return 'Floor'
      case c.TYPE_ROOM:
        return 'Room'
      default:
        return ''
    }
  }

  const handlePreviewFile = useCallback((file) => {
    const blob = URL.createObjectURL(file)
    setPreviewBlob(blob)
    return uploadCheck(file)
  }, [])

  useEffect(() => {
    setSpaceName(getSpaceType(spaceType))
  }, [spaceType])

  useEffect(() => {
    if (editSpace && editSpace.coverImage) {
      setFileList([{ name: editSpace.name, ImageUrl: editSpace.coverImage }])
      setUrlToImage(editSpace.coverImage)
    }
  }, [editSpace])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose
        bodyStyle={{ padding: '0' }}
        closable={false}
        footer={
          editSpace
            ? [
              <div key='buttons'>
                <FooterContainer>
                  <DeleteWrap>
                    <DeleteSpace
                      space={editSpace}
                      handleRemove={handleRemove}
                      setVisible={setVisible}
                    />
                  </DeleteWrap>
                  <ButtonContainer>
                    <Button
                      fill
                      outline
                      size='large'
                      key='cancel'
                      onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button
                      size='large'
                      loading={loading}
                      key='submit'
                      type='primary'
                      htmlType='submit'
                      form='addSpace'
                    >
                      {`Update ${spaceName}`}
                    </Button>
                  </ButtonContainer>
                </FooterContainer>
              </div>
            ]
            : [
              <FooterContainer key='buttons'>
                <ButtonContainer>
                  <Button fill outline key='cancel' onClick={handleCancel}>
                      Cancel
                  </Button>
                </ButtonContainer>
                <ButtonContainer>
                  <Button
                    loading={loading}
                    key='submit'
                    type='primary'
                    htmlType='submit'
                    form='addSpace'
                  >
                    {`Add ${spaceName}`}
                  </Button>
                </ButtonContainer>
              </FooterContainer>
        ]
        }
      >
        <ModalContainer>
          <TitleContainer antd={antd}>
            {editSpace ? (
              <h2>{`Update ${editSpace.name}`}</h2>
            ) : (
              <h2>{`Add ${spaceName}`}</h2>
            )}
          </TitleContainer>
          <FormContainer>
            <UploadWrapper>
              <PreviewImage src={urlToImage || previewBlob} />
            </UploadWrapper>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='horizontal'
              size='large'
              id='addSpace'
              onFinish={handleSubmit}
              initialValues={{
                type: spaceType,
                ...editSpace
              }}
            >
              <Form.Item hidden name='type'>
                <Radio.Group
                  className='pre-selected-floor'
                  disabled
                  type='primary'
                >
                  <Radio.Button value={1}>Building</Radio.Button>
                  <Radio.Button value={2}>Floor</Radio.Button>
                  <Radio.Button value={3}>Room</Radio.Button>
                </Radio.Group>
              </Form.Item>
              {spaceType === c.TYPE_ROOM && (
                <Form.Item name='category'>
                  <Radio.Group className='pre-selected-floor' type='primary'>
                    {Object.entries(roomStyles).map(([key, value], i) => {
                      if (key === 'default') return null
                      return (
                        <Radio.Button key={i} value={key}>
                          {value.displayName}
                        </Radio.Button>
                      )
                    })}
                  </Radio.Group>
                </Form.Item>
              )}
              <Form.Item name='name'>
                <Input placeholder={`${spaceName} name`} />
              </Form.Item>
              <Form.Item name='description'>
                <Input.TextArea placeholder={`${spaceName} description`} />
              </Form.Item>
              {/* <Form.Item name='location'>
                <Input placeholder='Country' />
              </Form.Item>
              <Form.Item name='address'>
                <Input placeholder='Address' />
              </Form.Item> */}
              <Form.Item
                name='coverImageContainer'
                valuePropName='fileList'
                getValueFromEvent={normFile}
                noStyle
              >
                <StyledDragger
                  name='file'
                  customRequest={(file) => getSignedUrl(file)}
                  beforeUpload={handlePreviewFile}
                  showUploadList={false}
                >
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    Click or drag file to this area to upload
                  </p>
                </StyledDragger>
              </Form.Item>
              {progressUpload && (
                <Progress
                  status={uploadStatus ? 'active' : 'exception'}
                  percent={progressUpload}
                />
              )}
              {fileList.map((item, i) => {
                const { name } = item
                return (
                  <FileListContainer key={i}>
                    {/* <img src={urlToImage} /> */}
                    <FileNameWrap>
                      <CheckCircleOutlined />
                      <a>{name}</a>
                    </FileNameWrap>
                    <DeleteOutlined onClick={(item) => clickedDelete(item)} />
                  </FileListContainer>
                )
              })}
            </Form>
          </FormContainer>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default AddSpace
