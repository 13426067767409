import React, { useState } from 'react'
import {
  Modal,
  Form,
  Input,
  Button as AntButton,
  Radio,
  Upload,
  message,
  Progress,
  Select,
  Popover,
  Tag
} from 'antd'
import { InboxOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { addSpace, updateSpace, useCurrentState, getUrlBucket, uploadImageBucket, deleteImageUrl, useAdminAllSpaces } from 'src/hooks'
import Button from 'src/components/Button'
import Spin from 'src/components/Spin'

import * as c from 'src/constants'

import styled from 'styled-components'
const { Option } = Select

const FormContainer = styled.div`
  padding: 2em 3em;
  & .ant-row.ant-form-item {
    justify-content: center;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
`

const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`

const ModalContainer = styled.div`

`

const FileListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em;
`

const FileNameWrap = styled.div`
  display: flex;
  & span {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 0.5em;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonContainer = styled.div`
  padding: 0.5em;
`

/**
 * @typedef AdminAddSpaceModalProps
 * @type {object}
 * @property {?string} title
 * @property {?boolean} edit
 * @property {?boolean} stopPropagation
 * @property {?*} record
 * @property {*} editSpace
 * @property {*} setEditSpace
 * @property {*} shouldUpdate
 */

/**
 * @param {AdminAddSpaceModalProps} props
 * @returns {React.ReactNode}
 */

const AdminAddSpaceModal = (props) => {
  const {
    title,
    edit,
    record,
    stopPropagation,
    editSpace,
    setEditSpace,
    shouldUpdate
  } = props
  const { client } = useCurrentState()
  const { antd } = client
  const { spaces, loading: spaceLoading } = useAdminAllSpaces(edit)
  const [loading, setLoading] = useState(false)
  const [urlToImage, setUrlToImage] = useState(null)
  const [fileList, setFileList] = useState([])
  const [progressUpload, setProgressUpload] = useState()
  const [uploadStatus, setUploadStatus] = useState()
  const [visible, setVisible] = useState(false)

  const handleSubmit = e => {
    setLoading(true)
    console.log(e)
    const newSpace = {
      ...(edit && record) ? record : {},
      coverImage: urlToImage ?? (record?.coverImage ?? null),
      ...e
    }

    if (edit) {
      updateSpace(newSpace)
        .then((res) => {
          setLoading(false)
          setVisible(false)
          shouldUpdate()
        }).catch(() => {
          setLoading(false)
        })
    } else {
      addSpace(newSpace)
        .then((res) => {
          setLoading(false)
          setVisible(false)
          shouldUpdate()
        }).catch(() => {
          setLoading(false)
        })
    }
  }

  const handleCancel = e => {
    setVisible(false)
    if (editSpace) {
      setEditSpace()
    }
    if (urlToImage) {
      deleteImageUrl(urlToImage, false).then((res) => {
        setUrlToImage('')
        setFileList([])
      })
    }
  }

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const uploadCheck = (file) => {
    if (fileList && fileList.length < 1) {
      return true
    } else {
      message.info('You can only upload one image for each space')
      return false
    }
  }

  const clickedDelete = () => {
    setLoading(true)
    deleteImageUrl(urlToImage).then((res) => {
      const tempList = []
      setUrlToImage('')
      setFileList(tempList)
      setLoading(false)
    }).catch(() =>
      setLoading(false)
    )
  }

  const callbackProgress = (value) => {
    setProgressUpload(value)
  }

  const getSignedUrl = async (options) => {
    const { onSuccess, onError, file } = options
    const { name, uid, type } = file
    const fileProps = { name, uid, type }
    const key = uid
    setUploadStatus(true)
    setProgressUpload(1)
    message.loading({ content: (`${name} file uploading.`), key, duration: 0 })
    try {
      getUrlBucket(fileProps).then((res) => {
        const { File } = window
        const newFile = new File([file], res.fileName)
        return uploadImageBucket(res.url, newFile, key, callbackProgress)
          .then((res) => {
            const parts = res.split('?')
            const ImageUrl = parts[0]
            setFileList([{ name, ImageUrl }])
            setUrlToImage(ImageUrl)
            setProgressUpload(undefined)
            onSuccess('OK')
          })
          .catch((err) => {
            setUploadStatus(false)
            message.loading({ content: (`${name} file uploading.`), key, duration: 0.001 })
            onError(err)
          })
      })
    } catch (err) {
      setUploadStatus(false)
      message.loading({ content: (`${name} file uploading.`), key, duration: 0.001 })
      onError(err)
    }
  }

  const getSpaceTag = (type) => {
    if (type === c.TYPE_ROOM) return <Tag color='geekblue'>Room</Tag>
    if (type === c.TYPE_FLOOR) return <Tag color='blue'>Floor</Tag>
    if (type === c.TYPE_BUILDING) return <Tag color='orange'>Building</Tag>
  }

  return (
    <>
      {
        edit
          ? (
            <AntButton
              type='link'
              onClick={(e) => {
                if (stopPropagation) e.stopPropagation()
                setVisible(true)
              }}
            >
              Edit
            </AntButton>)
          : (
            <Button type='primary' onClick={() => setVisible(true)}>
              Add Space
            </Button>)
      }
      <Modal
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose
        bodyStyle={{ padding: '0' }}
        closable={false}
        footer={
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button loading={loading.toString()} key='submit' type='primary' htmlType='submit' form='addSpace'>
                {edit ? 'Update space' : 'Add space'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        }
      >
        {visible &&
          <ModalContainer>
            <TitleContainer antd={antd}>
              <h2>{title ?? 'Add space'}</h2>
            </TitleContainer>
            <FormContainer>
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout='horizontal'
                size='large'
                id='addSpace'
                onFinish={handleSubmit}
                initialValues={{ ...record }}
              >
                <Form.Item
                  name='type'
                  required
                >
                  <Radio.Group className='pre-selected-floor' type='primary' disabled={edit}>
                    <Radio.Button value={1}>Building</Radio.Button>
                    <Radio.Button value={2}>Floor</Radio.Button>
                    <Radio.Button value={3}>Room</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name='name'
                  required
                >
                  <Input placeholder='Space name' />
                </Form.Item>
                <Form.Item name='description'>
                  <Input.TextArea placeholder='Space description' />
                </Form.Item>
                <Form.Item name='location'>
                  <Input placeholder='Country' />
                </Form.Item>
                <Form.Item name='address'>
                  <Input placeholder='Address' />
                </Form.Item>
                <Form.Item name='parentSpaceId'>
                  {spaceLoading
                    ? <Spin />
                    : (
                      <Select
                        showSearch
                        allowClear
                        placeholder='Select parent space'
                        defaultValue={undefined}
                        filterOption={(input, option) => {
                          const { key = '' } = option
                          return key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}

                      >
                        {spaces && spaces.map(space => {
                          return (
                            <Option key={`${space.id} - ${space.name}`} value={space.id}>
                              <Popover placement='left' content={space.description}>
                                {`${space.id} - ${space.name}`}
                              </Popover>
                            </Option>)
                        }
                        )}
                      </Select>)}
                </Form.Item>
                <Form.Item name='coverImageContainer' valuePropName='fileList' getValueFromEvent={normFile} noStyle>
                  <Upload.Dragger
                    name='file'
                    customRequest={(file) => getSignedUrl(file)}
                    beforeUpload={(file) => uploadCheck(file)}
                    showUploadList={false}
                  >
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    <p className='ant-upload-hint'>Support for a single or bulk upload.</p>
                  </Upload.Dragger>
                </Form.Item>
                {progressUpload &&
                  <Progress status={uploadStatus ? 'active' : 'exception'} percent={progressUpload} />}
                {fileList.map((item, i) => {
                  const { name } = item
                  return (
                    <FileListContainer key={i}>
                      <FileNameWrap>
                        <CheckCircleOutlined />
                        <a>{name}</a>
                      </FileNameWrap>
                      <DeleteOutlined onClick={(item) => clickedDelete(item)} />
                    </FileListContainer>
                  )
                })}
              </Form>
            </FormContainer>
          </ModalContainer>}
      </Modal>
    </>
  )
}

export default AdminAddSpaceModal
