export const TYPE_BUILDING = 1
export const TYPE_FLOOR = 2
export const TYPE_ROOM = 3

export const TAB_USER_DATA = 1
export const TAB_MACHINE_DATA = 2

export const SMALL_SCREEN = '767px'
export const MEDIUM_SCREEN = '1200px'

export const ACCESS_READ = 1
export const ACCESS_WRITE = 2
export const ACCESS_ADMIN = 3

export const ROOM_KITCHEN = 1
export const ROOM_BEDROOM = 2
export const ROOM_BATHROOM = 3
export const ROOM_TOILET = 4
export const ROOM_HALL = 5
export const ROOM_OFFICE = 6

export const SPACE_USER = 1
export const SPACE_INSTALLER = 2
export const SPACE_OWNER = 3

export const CHART_HOUR = 1
export const CHART_DAY = 2
export const CHART_WEEK = 3
export const CHART_MONTH = 4
export const CHART_YEAR = 5
