import styled, { css } from 'styled-components'
import { Button as AntButton } from 'antd'
import * as c from 'src/constants'

const Button = styled.button`
  cursor: pointer;
  border-radius: 0.5rem;
  box-shadow: 0 5px 15px rgba(0,0,0,0.25);
  border: none;
  padding: 0.5rem 1rem;
  font-size: ${props => props.fontSize || '22px'};
  min-width: 150px;
  width: ${props => props.fill ? '100%' : 'auto'};
  background-color: ${props => props.backgroundColor || props.theme.primaryColor || '#7297ff'};
  color: ${props => props.color || 'white'};
  transition: opacity 0.25s ease-out, box-shadow 0.5s ease-out;
  ${props => props.lighter && css`
    font-weight: lighter;
  `}
  &:hover {
    opacity: 0.75;
    box-shadow: 0 0px 10px rgba(0,0,0,0.15);
  }
  @media (max-width: ${c.SMALL_SCREEN}) {
    min-width: 100px;
    font-size: 1rem;
  }
`

const ButtonFake = styled.div`
  cursor: pointer;
  border-radius: 0.5rem;
  box-shadow: 0 5px 15px rgba(0,0,0,0.25);
  border: none;
  padding: 0.5rem 1rem;
  font-size: 22px;
  min-width: 150px;
  width: ${props => props.fill ? '100%' : 'auto'};
  background-color: ${props => props.backgroundColor || '#7297ff'};
  color: ${props => props.color || 'white'};
  transition: opacity 0.25s ease-out, box-shadow 0.5s ease-out;
  ${props => props.lighter && css`
    font-weight: lighter;
  `}
  &:hover {
    opacity: 0.75;
    box-shadow: 0 0px 10px rgba(0,0,0,0.15);
  }
  @media (max-width: ${c.SMALL_SCREEN}) {
    min-width: 100px;
    font-size: 1rem;
  }
`
const ButtonFakeOutline = styled(ButtonFake)`
  background-color: transparent;
  border-color: ${props => props.color || '#7297ff'};
  border-style: solid;
  border-width: 1px;
  color: ${props => props.color || '#7297ff'};
  box-shadow: none;
  &:hover {
    opacity: 0.5;
    box-shadow: none;
  }
`

const ButtonOutline = styled(Button)`
  background-color: transparent;
  border-color: ${props => props.color || props.theme.primaryColor || '#7297ff'};
  border-style: solid;
  border-width: 1px;
  color: ${props => props.color || props.theme.primaryColor || '#7297ff'};
  box-shadow: none;
  &:hover {
    opacity: 0.5;
    box-shadow: none;
  }
`

const ButtonComponent = ({ children, ...props }) => {
  const {
    outline,
    fake,
    ...rest
  } = props
  if (fake && outline) return <ButtonFakeOutline {...rest}>{children}</ButtonFakeOutline>
  else if (outline) return <ButtonOutline {...rest}>{children}</ButtonOutline>
  else if (fake) return <ButtonFake {...rest}>{children}</ButtonFake>
  else return <Button {...rest}>{children}</Button>
}

export const BackButton = (props) =>
  <AntButton type='link' {...props}>
    <i className='fas fa-arrow-left' style={{ color: 'black', fontSize: '1.5rem' }} />
  </AntButton>

export const RightArrowButton = (props) =>
  <AntButton type='link' {...props}>
    <i className='fas fa-caret-right' style={{ color: 'black', fontSize: '1.5rem' }} />
  </AntButton>

export default ButtonComponent
