import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  message,
  Switch
} from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

import { useState } from 'react'
import Button from 'src/components/Button'
import { adminAddSensorType, adminUpdateSensorType } from 'src/hooks'
import styled from 'styled-components'
import * as c from '../../constants'

const METAOBJECT = { displayName: '', key: '' }

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & .ant-form-item-control{
    padding: 0 0.5em;
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  & button {
    min-width: 60px;
  }
`

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AdminAddSensorTypeModal = (props) => {
  const {
    parentSpaceId,
    edit = false,
    record = {},
    shouldUpdate
  } = props

  const [visible, setVisible] = useState(false)
  const [metaData, setMetaData] = useState([])
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    message.destroy(parentSpaceId)
    form.resetFields()
    setVisible(false)
  }

  const UpdateFields = (add) => {
    if (add) {
      setMetaData([...metaData, METAOBJECT])
    } else {
      const list = [...metaData]
      list.pop()
      setMetaData(list)
    }
  }

  const handleSubmit = item => {
    const { fwVersion, type, allowAutoUpgrade = false } = item

    console.log('item', item)
    const groupedMeta = Object.keys(item)
      .reduce((acc, cur) => {
        const parts = cur.split('^@^')
        const [n, key] = parts
        if (key) {
          return {
            ...acc,
            [n]: {
              ...acc[n],
              [key]: item[cur]
            }
          }
        } else {
          return {
            ...acc
          }
        }
      }, {})

    const arrayMeta = Object.keys(groupedMeta).map(i => groupedMeta[i])
    const metaData = arrayMeta.reduce((r, a) => {
      r[a.object] = [...r[a.object] || [], a]
      return r
    }, {})

    const payload = {
      ...item,
      allowAutoUpgrade,
      fwVersion: parseInt(fwVersion),
      type: parseInt(type),
      metaData: JSON.stringify(metaData)
    }

    if (edit) {
      const editPayload = { id: record.id, ...payload }
      adminUpdateSensorType(editPayload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        }).catch(() => {})
    } else {
      adminAddSensorType(payload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        }).catch(() => {})
    }
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
        Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
        Add sensor type
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addSensorType'>
                {edit ? 'Update sensor type' : 'Add sensor type'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit sensor type' : 'Add sensor type'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addSensorType'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                ...record,
                admin: (record.admin),
                active: (record.active === 1),
                terms: (record.terms === 1)
              }}
            >
              <Row>
                <Form.Item
                  label='Name'
                  name='name'
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please add name'
                    }
                  ]}
                >
                  <Input
                    placeholder='Name'
                    allowClear

                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  label='Description'
                  name='description'
                  hasFeedback
                >
                  <Input.TextArea
                    placeholder='Description'
                    allowClear

                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  label='Type'
                  name='type'
                >
                  <Input
                    placeholder='Type'
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  label='FW version'
                  name='fwVersion'
                >
                  <Input
                    placeholder='FW version'
                    allowClear
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  label='Filename'
                  name='filename'
                >
                  <Input
                    placeholder='Filename'
                    allowClear
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  label='Allow auto upgrade'
                  name='allowAutoUpgrade'
                >
                  <Switch />
                </Form.Item>
              </Row>
              <Form.Item>
                <Right>
                  <ColumnWrap>
                    <span>Add meta data</span>
                    <div style={{ display: 'flex' }}>
                      <Button fake outlined style={{ borderRadius: '10px 0 0 10px', minWidth: '25px' }} outline onClick={() => UpdateFields(false)}>
                        <MinusOutlined />
                      </Button>
                      <Button fake style={{ borderRadius: '0 10px 10px 0', minWidth: '25px' }} onClick={() => UpdateFields(true)}>
                        <PlusOutlined />
                      </Button>
                    </div>
                  </ColumnWrap>
                </Right>
              </Form.Item>
              {metaData.map((item, i) => {
                return (
                  <>
                    <Form.Item
                      label='Meta data object'
                      name='meta'
                    >
                      <Form.Item
                        key={`${i}^@^object`}
                        name={`${i}^@^object`}
                        required
                      >
                        <Input
                          type='input'
                          placeholder='Object name'
                          allowClear
                        />
                      </Form.Item>
                      <Form.Item
                        key={`${i}^@^displayName`}
                        name={`${i}^@^displayName`}
                        required
                      >
                        <Input
                          type='input'
                          placeholder='Display name'
                          allowClear
                        />
                      </Form.Item>
                      <Form.Item
                        key={`${i}^@^key`}
                        name={`${i}^@^key`}
                        required
                      >
                        <Input
                          type='input'
                          placeholder='Key' allowClear
                        />
                      </Form.Item>
                    </Form.Item>
                  </>)
              })}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </div>
  )
}

export default AdminAddSensorTypeModal
