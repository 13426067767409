import { Link, routes } from '@redwoodjs/router'
import styled from 'styled-components'

import LangDropdown from 'src/components/LangDropdown'

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`

const HeaderLogin = styled.div`
  display: flex;
  justify-content: flex-end;
`

const LoginMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoginLayout = ({ children }) => {
  return (
    <>
      <HeaderLogin>
        <LangDropdown />
      </HeaderLogin>
      <LoginContainer>
        <LoginMain>{children}</LoginMain>
      </LoginContainer>
      {/* </Footer> */}
    </>
  )
}

export default LoginLayout
