import React, { useState } from 'react'
import styled from 'styled-components'
import { useCurrentState } from 'src/hooks'

const LogoContainer = styled.div`
  margin: 2em;
  text-align: center;
`

const LogoImg = styled.img`
  max-width: 256px;
  width: 80%;
  margin: 0 auto;
`
const RegisterHeader = () => {
  const { client } = useCurrentState()
  const { assets } = client
  return (
    <LogoContainer>
      <LogoImg src={assets.logoWide} alt='Logo' />
    </LogoContainer>
  )
}

export default RegisterHeader
